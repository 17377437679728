import React from "react";
import {
  Pressable,
  StyleSheet,
  View,
  Image,
  GestureResponderEvent,
} from "react-native";
import { colours } from "../shared/theme";

interface Props {
  isChatScreen?: boolean;
  onBackPress?: (event: GestureResponderEvent) => void;
  onPalletePress?: (event: GestureResponderEvent) => void;
}
const Header = ({
  isChatScreen = false,
  onBackPress,
  onPalletePress,
}: Props) => {
  return (
    <View style={styles.header}>
      {onBackPress ? (
        <Pressable onPress={onBackPress} style={styles.headerIconWrap}>
          <Image
            source={require("../assets/img/arrow-back.png")}
            style={[styles.headerIcon, { height: 18 }]}
            resizeMode="contain"
          />
        </Pressable>
      ) : (
        <View />
      )}
      <Image
        source={
          isChatScreen
            ? require("../assets/img/DB-Robot.png")
            : require("../assets/img/logo.png")
        }
        style={styles.headerImage}
      />
      {onPalletePress ? (
        <Pressable onPress={onPalletePress} style={styles.headerIconWrap}>
          <Image
            source={require("../assets/img/icon-colour-palette.png")}
            style={styles.headerIcon}
            resizeMode="contain"
          />
        </Pressable>
      ) : (
        <View style={styles.headerIconWrap} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: colours.yellow,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    height: 80,
  },
  headerImage: {
    width: 80,
    resizeMode: "contain",
    flex: 1,
  },
  headerIconWrap: {
    alignContent: "center",
    justifyContent: "center",
    width: 22,
  },
  headerIcon: {
    width: 22,
    flex: 1,
  },
});

export default Header;
