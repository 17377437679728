// Video element only for RNWeb - will need a different solution for RN.
// https://stackoverflow.com/a/59333885

import React from "react";
import { ImageProps, StyleSheet, View } from "react-native";
import { createElement } from "react-native-web";

interface Props {
  source: "string";
  poster?: ImageProps;
  controls?: boolean;
}
const Video = ({ source, poster, controls = true }: Props) => {
  return (
    <View style={styles.container}>
      {createElement("video", {
        src: source,
        poster,
        controls,
        style: {
          maxWidth: "100%",
          borderRadius: 5,
          overflow: "hidden",
        },
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: "100%",
  },
});

export default Video;
