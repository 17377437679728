import React from "react";
import { StyleSheet, Image } from "react-native";
import { InputToolbar, Send } from "react-native-gifted-chat";

import { colours } from "../shared/theme";

const Toolbar = (props) => (
  <InputToolbar
    {...props}
    containerStyle={[
      styles.toolbar,
      {
        pointerEvents: props.preventInput ? "none" : "auto",
      },
    ]}
    renderSend={(props) => (
      <Send {...props} containerStyle={styles.sendWrap}>
        <Image
          source={require("../assets/img/icon-send.png")}
          style={styles.send}
        />
      </Send>
    )}
  />
);

export default Toolbar;

const styles = StyleSheet.create({
  toolbar: {
    borderRadius: 30,
    borderColor: colours.yellow,
    borderTopColor: colours.yellow,
    borderWidth: 2,
    borderTopWidth: 2,
    margin: 5,
    paddingBottom: 6,
  },
  sendWrap: {
    justifyContent: "center",
    paddingTop: 6,
    borderWidth: 0,
    paddingHorizontal: 10,
    height: 44,
  },
  send: {
    width: 20,
    height: 20,
  },
});
