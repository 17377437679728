import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Linking } from "react-native";
import { startSession, messageRequest, endSession } from "../shared/Assistant";
import {
  Bubble,
  GiftedChat,
  IMessage,
  Message,
} from "react-native-gifted-chat";
// import Video from "react-native-video"; // Doesn't seem to work with RNWeb
import { v4 as uuidv4 } from "uuid";

import Video from "../components/Video";
import Link from "../components/Link";
import Image from "../components/Image";
import LottieInstance from "../components/LottieInstance";
import Toolbar from "../components/Toolbar";
import Header from "../components/Header";
import { colours, fontFamilies } from "../shared/theme";
// import { MessageResponseGeneric } from "./shared/types";

interface Props {
  onBack: Function;
}

const Chat = ({ onBack }: Props) => {
  const [messages, setMessages] = useState([]);
  const [pendingMessages, setPendingMessages] = useState([]); // Queued messages e.g. more search results
  const [isTyping, setIsTyping] = useState(true);
  const [userId, setUserId] = useState("digibeteapp");
  const [sessionId, setSessionId] = useState("");
  const [context, setContext] = useState(null);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const renderCustomView = (props) => {
    const { currentMessage } = props;
    if (currentMessage.response_type === "text") {
      const isLink = currentMessage.text.startsWith("http");
      if (isLink) {
        const parts = currentMessage.text.split(" | ");
        const url = parts[0];
        const text = parts.length > 1 ? parts[1] : null;
        return <Link url={url} text={text} />;
      } else {
        return (
          <View>
            <Text style={styles.bubbleText}>{currentMessage.text}</Text>
          </View>
        );
      }
    } else if (currentMessage.response_type === "search") {
      return (
        <View>
          <Text style={styles.bubbleText}>{currentMessage.text}</Text>
        </View>
      );
    } else if (currentMessage.response_type === "lottie") {
      return (
        <View>
          <LottieInstance source={currentMessage.lottie_src} />
        </View>
      );
    } else if (currentMessage.response_type === "search_result") {
      return (
        <Link
          url={currentMessage.url}
          text={currentMessage.text}
          body={currentMessage.body}
        />
      );
    }
    return null;
  };

  const renderVideo = (props) => {
    const { currentMessage } = props;

    return (
      <View style={styles.mb2}>
        {currentMessage.title && (
          <Text style={[styles.bubbleText, styles.bubbleTextTitle]}>
            {currentMessage.title}
          </Text>
        )}
        {currentMessage.description && (
          <Text style={[styles.bubbleText, styles.bubbleTextDescription]}>
            {currentMessage.description}
          </Text>
        )}
        <Video source={currentMessage.video} />
      </View>
    );
  };

  const renderImage = (props) => {
    const { currentMessage } = props;

    return (
      <View style={styles.mb2}>
        {currentMessage.title && (
          <Text style={[styles.bubbleText, styles.bubbleTextTitle]}>
            {currentMessage.title}
          </Text>
        )}
        {currentMessage.description && (
          <Text style={[styles.bubbleText, styles.bubbleTextDescription]}>
            {currentMessage.description}
          </Text>
        )}
        <Image source={currentMessage.image} />
      </View>
    );
  };

  const renderMessage = (props) => {
    const { currentMessage } = props;

    // const bubblesToRender = [currentMessage];

    // if (currentMessage.childMessages && currentMessage.childMessages.length > 0) {
    //   console.log('childmessages', currentMessage.childMessages);
    //   bubblesToRender.push(
    //     ...currentMessage.childMessages.map((message) => {
    //       return {
    //         ...message,
    //         text: message.title,
    //       };
    //     })
    //   );
    //   console.log('childmessages mapped - bubblesToRender', bubblesToRender);
    // }
    // return bubblesToRender.map((b, index) => (
    //   <Message {...props} key={b._id ? b._id : b.id ? b.id : index} />
    // ));
    return <Message {...props} />;
  };

  const renderBubble = (props) => {
    const { currentMessage } = props;
    const hasText = currentMessage.text && currentMessage.text.length > 0;

    return (
      <Bubble
        {...props}
        textStyle={{
          right: {
            ...styles.bubbleText,
            marginRight: 5,
            color: "#FFF",
            textAlign: "right",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          },
          left: {
            ...styles.bubbleText,
            color: colours.blackAlt,
            textAlign: "left",
          },
        }}
        wrapperStyle={{
          left: {
            padding: hasText ? 10 : 0,
            paddingBottom: 0,
            backgroundColor: "#EAF4FB",
            marginBottom: 5,
            borderBottomLeftRadius: 3,
          },
          right: {
            backgroundColor: colours.yellow,
            paddingBottom: 0,
            marginBottom: 5,
            borderBottomRightRadius: 3,
          },
        }}
        containerToNextStyle={{
          left: {
            borderBottomLeftRadius: 3,
          },
          right: {
            borderBottomRightRadius: 3,
          },
        }}
        containerToPreviousStyle={{
          left: {
            borderTopLeftRadius: 15,
            borderBottomLeftRadius: 3,
          },
          right: {
            borderTopRightRadius: 15,
            borderBottomRightRadius: 3,
          },
        }}
        bottomContainerStyle={{
          left: { marginLeft: -10, marginTop: 5 },
        }}
        renderTime={
          hasText ? undefined : () => null // Don't render the time if there is no message text content
        }
        renderMessageText={
          props.currentMessage.user._id !== "1" ? () => null : undefined // Don't render message text for chatbot replies, as we are handling this ourselves in renderCustomView
        }
        renderMessageVideo={renderVideo}
        renderMessageImage={renderImage}
      />
    );
  };

  const clearPendingMessages = () => setPendingMessages([]);

  const onQuickReply = (replyArr) => {
    if (replyArr[0].value && replyArr[0].value === "start_new_chat") {
      attemptStartSession();
    } else if (replyArr[0].value && replyArr[0].value === "more_results") {
      // If this is to show more results
      setMessages(GiftedChat.append(messages, pendingMessages));
      clearPendingMessages();
    } else {
      // Transform replies to messages and send
      const mapped = replyArr.map((reply) => {
        return {
          text: reply.title,
          _id: uuidv4(),
          user: {
            _id: "1",
          },
          createdAt: new Date(),
        };
      });
      onSend(mapped);
      // Now we need to remove any quick reply messages from the gifted chat array so the user can't select one again.
      setMessages((messages) => messages.filter((msg) => !msg.quickReplies));
    }
  };

  const onSend = (messageArr: IMessage[]) => {
    setMessages(GiftedChat.append(messages, messageArr));
    getMessage(messageArr[0].text.replace(/[\n\r]+/g, " ").toLowerCase());
  };

  const handleBackPress = () => {
    if (window.confirm("Do you want to end the chat?")) {
      handleEndSession();
      onBack();
    }
  };

  const handleEndSession = async () => {
    try {
      const ending = await endSession(sessionId);
      clearPendingMessages();
      // Add a new system message with a quick reply to start a new session
      const startAgainMessage = {
        _id: uuidv4(),
        createdAt: new Date(),
        user: {
          _id: userId,
        },
        quickReplies: {
          type: "radio",
          keepIt: false,
          values: [
            {
              title: "Start a new chat",
              value: "start_new_chat",
            },
          ],
        },
      };
      const systemEndedMessage = {
        _id: uuidv4(),
        createdAt: new Date(),
        user: {
          _id: userId,
        },
        text: "Your chat has ended",
        system: true,
      };
      setMessages(
        GiftedChat.append(messages, [startAgainMessage, systemEndedMessage])
      );
      setSessionId("");
      setSessionEnded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getMessage = async (text: string, session_id?: string) => {
    setIsTyping(true);

    try {
      const response = await messageRequest(
        session_id || sessionId,
        userId,
        text,
        context
      );
      setContext(response.context);

      const { output } = response;
      const responseGeneric = output.generic;

      // console.log(responseGeneric);
      clearPendingMessages();

      const responses = responseGeneric
        .map((item) => {
          const itemBase = {
            _id: uuidv4(),
            createdAt: new Date(),
            user: {
              _id: userId,
            },
          };
          if (item.response_type === "search") {
            const { additional_results } = item;
            if (additional_results) {
              setPendingMessages(
                additional_results.map((result) => {
                  return {
                    ...itemBase,
                    _id: uuidv4(),
                    response_type: "search_result",
                    text: result.title ? result.title.slice(0, 70) : result.url,
                    ...result,
                  };
                })
              );
            }

            return [
              {
                ...itemBase,
                text: item.header,
                response_type: item.response_type,
                childMessages: item.primary_results,
              },
              ...item.primary_results.map((result, index) => {
                let message = {
                  ...itemBase,
                  _id: uuidv4(),
                  response_type: "search_result",
                  text: result.title ? result.title.slice(0, 70) : result.url,
                  ...result,
                };

                // if there are additional results, add a quick reply to the last primary result to be able to show them
                const showQuickReply =
                  index + 1 === item.primary_results.length &&
                  additional_results;
                if (showQuickReply) {
                  message.quickReplies = {
                    type: "radio",
                    keepIt: false,
                    values: [
                      {
                        title: "More Results",
                        value: "more_results",
                      },
                    ],
                  };
                }

                return message;
              }),
            ];
          } else if (item.response_type === "video") {
            return {
              ...itemBase,
              text: item.title ?? "",
              video: item.source,
              ...item,
            };
          } else if (item.response_type === "image") {
            return {
              ...itemBase,
              text: item.title ?? "",
              image: item.source,
              ...item,
            };
          } else if (item.response_type === "user_defined") {
            const customData = item.user_defined; // Defined by us in the assistant response JSON
            const type = customData.ud_type;
            if (type === "lottie") {
              return {
                ...itemBase,
                ...item,
                text: "",
                response_type: type,
                lottie_src: customData.lottie_src,
              };
            } else {
              return null;
            }
          } else if (item.response_type === "option") {
            const optionsMappedToGCValues = item.options.map((opt) => {
              return {
                title: opt.label,
                value: opt.value?.input?.text,
              };
            });
            return {
              ...itemBase,
              text: "",
              quickReplies: {
                type: "radio",
                keepIt: false,
                values: optionsMappedToGCValues,
              },
            };
          } else {
            return {
              ...itemBase,
              text: item.text ?? item.title ?? "",
              ...item,
            };
          }
        })
        .flat()
        // .filter((i) => i.text.length > 0)
        .reverse();

      // Check if any responses are 'pause' reponse type.
      // If they are, split into multiple arrays of message, splitting at the pause messages.
      // set timeouts as necessary to add the arrays to gifted chat messages state,
      // with actual timeout depending on pause message length.
      const containsPauses = responses.find((r) => r.response_type === "pause");

      if (containsPauses) {
        let arrayIndex = 0;
        let pauses: number[] = [];
        const responseArrays = responses
          .reduce(
            (prev, curr) => {
              if (curr.response_type === "pause") {
                arrayIndex++;
                pauses.push(curr.time ?? 2000); // Add a 'pause' time for this
                prev[arrayIndex] = []; // Move on to next array
              } else {
                prev[arrayIndex].push(curr);
              }
              // console.log({ prev }, { arrayIndex });
              return prev;
            },
            [[]]
          )
          .reverse();

        pauses.push(0); // First message should send immediately
        pauses.reverse(); // to match the messages being reversed

        let pauseValue = pauses[0];
        responseArrays.forEach((array, index) => {
          pauseValue += pauses[index]; // We need to add to total timeout each time

          setTimeout(
            () =>
              setMessages((previousMessages) =>
                GiftedChat.append(previousMessages, array)
              ),
            pauseValue
          );

          // If it's the last one, set a timeout for isTyping, too,
          // so that the user can only type once all messages are added to state
          if (index === responseArrays.length - 1) {
            setTimeout(() => setIsTyping(false), pauseValue);
          }
        });
      } else {
        setMessages((previousMessages) =>
          GiftedChat.append(previousMessages, responses)
        );
        setIsTyping(false);
      }
    } catch (error) {
      console.error(error);
      setIsTyping(false);
    }
  };

  const attemptStartSession = async () => {
    const data = await startSession();
    setMessages([]);

    if (data && data.session_id) {
      console.log("setting sessionId of ", data.session_id);
      setSessionId(data.session_id);

      // Get first message with no content, to trigger watson's initial message(s)
      await getMessage("", data.session_id);

      setSessionEnded(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      attemptStartSession();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <View style={styles.container}>
      <Header onBackPress={handleBackPress} isChatScreen />

      <GiftedChat
        placeholder={
          isLoading
            ? "Loading..."
            : isTyping
            ? "...thinking"
            : "Send your message..."
        }
        messages={messages}
        isTyping={isTyping} // doesn't seem to work on web
        onSend={(messages) => onSend(messages)}
        multiline={false}
        renderBubble={renderBubble}
        renderInputToolbar={
          sessionEnded
            ? () => null
            : (props) => (
                <Toolbar preventInput={isLoading || isTyping} {...props} />
              )
        }
        onQuickReply={(replies) => onQuickReply(replies)}
        renderAvatar={null}
        renderCustomView={renderCustomView}
        // renderMessage={renderMessage}
        listViewProps={{ showsVerticalScrollIndicator: false }} // Remove scrollbar on chat
        minInputToolbarHeight={sessionEnded ? 0 : 60}
        user={{
          _id: "1",
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  sessionEndedWrap: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
    padding: 30,
  },
  sessionEnded: {
    fontSize: 16,
    textAlign: "center",
  },
  restartButton: {
    margin: 10,
    textAlign: "center",
    backgroundColor: colours.yellow,
    padding: 15,
    justifyContent: "center",
  },
  restartButtonText: {
    fontSize: 14,
    color: "#FFF",
    textAlign: "center",
  },
  bubbleText: {
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    marginBottom: 2,
    color: "inherit",
    breakWord: "break-all",
    wordBreak: "break-word",
    fontFamily: fontFamilies.FredokaOne,
  },
  bubbleTextTitle: {
    fontWeight: "bold",
    marginBottom: 6,
  },
  bubbleTextDescription: {
    fontSize: 13,
    marginBottom: 6,
  },
  bubbleTextFiletype: {
    color: "#666",
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: 4,
  },
  mb2: {
    marginBottom: 2,
  },
  mt4: {
    marginTop: 4,
  },
  link: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    fontWeight: "bold",
  },
  messageImage: {
    width: undefined,
    height: undefined,
  },
});

export default Chat;
