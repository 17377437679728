import React, { View, StyleSheet } from "react-native";
import Animation from "lottie-react-native";

interface Props {
  source: string;
}

const LottieInstance = ({ source }: Props) => {
  const map = new Map();
  map.set("ROBOT_WAVING", require("../assets/json/Digibete-01-Wave.json"));
  map.set("WAVE_HELLO", require("../assets/json/Digibete-01-Wave.json"));
  map.set("LISTENING", require("../assets/json/Digibete-02-Listening.json"));
  map.set("THINKING", require("../assets/json/Digibete-03-Thinking.json"));
  map.set("CONFUSED", require("../assets/json/Digibete-04-Confused.json"));
  map.set("PROCESSING", require("../assets/json/Digibete-05-Processing.json"));
  map.set("WAVE_GOODBYE", require("../assets/json/Digibete-06-Wave.json"));

  const json = map.get(source);

  return json ? (
    <View style={styles.container}>
      <Animation source={json} autoPlay loop />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 200,
  },
});

export default LottieInstance;
