import React from "react";
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  Image,
  ScrollView,
  View,
} from "react-native";

import { colours, fontFamilies } from "../shared/theme";

interface Props {
  onLaunchChat: (event: GestureResponderEvent) => void;
}

const Home = ({ onLaunchChat }: Props) => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.imgTop}
        source={require("../assets/img/app-top.jpg")}
      />
      {/* <Header /> */}

      <ScrollView
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator={false}
      >
        <Image
          style={styles.imgInnerTop}
          source={require("../assets/img/app-home-top.jpg")}
        />
        <View style={styles.actionsContainer}>
          <View style={[styles.actionCard, styles.actionCardFirstCol]}>
            <View
              style={[
                styles.actionCardInner,
                { backgroundColor: colours.teal },
              ]}
            >
              <Image
                style={styles.actionIconTeens}
                source={require("../assets/home/home-btn-teens.png")}
              />
              <Text style={styles.actionText}>Ages 12-13 and Parents</Text>
            </View>
          </View>

          <View style={[styles.actionCard, styles.actionCardSecondCol]}>
            <Pressable
              onPress={onLaunchChat}
              style={[styles.actionCardInner, styles.actionCardQuiz]}
            >
              <Image
                style={[
                  styles.actionIcon,
                  {
                    width: 20,
                    height: 20,
                  },
                ]}
                source={require("../assets/home/home-btn-chat.png")}
              />
              <Text style={styles.actionText}>Chat</Text>
            </Pressable>
          </View>

          <View style={[styles.actionCard, styles.actionCardFirstCol]}>
            <View style={[styles.actionCardInner, styles.actionCardNews]}>
              <Image
                style={styles.actionIcon}
                source={require("../assets/home/home-btn-news.png")}
              />
              <Text style={styles.actionText}>News</Text>
            </View>
          </View>

          <View style={[styles.actionCard, styles.actionCardSecondCol]}>
            <View style={[styles.actionCardInner, styles.actionCardAwards]}>
              <Image
                style={styles.actionIcon}
                source={require("../assets/home/home-btn-awards.png")}
              />
              <Text style={styles.actionText}>My Awards</Text>
            </View>
          </View>
          <View style={styles.actionCardFullWidth}>
            <View style={[styles.actionCardInner, styles.actionCardClinic]}>
              <Image
                style={styles.actionIcon}
                source={require("../assets/home/home-btn-clinic.png")}
              />
              <Text style={styles.actionText}>My Clinic</Text>
            </View>
          </View>
        </View>
      </ScrollView>

      <Image
        style={styles.imgBottom}
        source={require("../assets/img/app-bottom.jpg")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    maxHeight: "100vh",
    scrollbarWidth: 0,
  },
  contentContainer: {
    flex: 1,
    scrollbarWidth: 0,
  },
  actionsContainer: {
    paddingHorizontal: 15,
    paddingBottom: 90,
    marginTop: 25,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  actionCard: {
    width: "50%",
    height: 120,
    marginBottom: 25,
  },
  actionCardFullWidth: {
    width: "100%",
    height: 120,
    marginBottom: 25,
  },
  actionCardFirstCol: {
    paddingRight: 12.5,
  },
  actionCardSecondCol: {
    paddingLeft: 12.5,
  },
  actionCardQuiz: {
    backgroundColor: colours.tealLight,
  },
  actionCardNews: {
    backgroundColor: colours.blueLight,
  },
  actionCardAwards: {
    backgroundColor: colours.blueDark,
  },
  actionCardClinic: {
    backgroundColor: colours.teal,
  },
  actionCardInner: {
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: colours.purple,
  },
  actionIcon: {
    marginBottom: 14,
    width: 30,
    height: 30,
  },
  actionIconTeens: {
    marginBottom: 14,
    width: 115,
    height: 25,
  },
  actionText: {
    paddingHorizontal: 30,
    marginBottom: 0,
    fontFamily: fontFamilies.FredokaOne,
    fontWeight: "bold",
    color: "#FFF",
    textAlign: "center",
  },
  actionTextNarrow: {
    paddingHorizontal: 15,
  },
  imgTop: {
    width: "100%",
    aspectRatio: 5.5495,
  },
  imgInnerTop: {
    width: "100%",
    aspectRatio: 1.6967,
  },
  imgBottom: {
    width: "100%",
    aspectRatio: 4.35789,
    position: "absolute",
    bottom: 0,
    left: 0,
  },
});

export default Home;
