export const colours = {
  yellow: "#F6A800",
  black: "#000000",
  blackAlt: "#002149",
  white: "#FFFFFF",
  whiteTranslucent: "rgba(255, 255, 255, 0.5)",
  blueAccent: "#98C6EB",
  blueLight: "#0072CE",
  blueDark: "#002149",
  eggBlue: "#00CCCC",
  green: "#83B630",
  greenMid: "#BDD124",
  greenAccent: "#C1DA98",
  grey: "#666",
  greyIncrediblyLight: "#F7F7F7",
  greyLight: "#EAEAEA",
  greyMedium: "#D7D4D4",
  greyInbetween: "#C9C8C8",
  greyTranslucent: "rgba(102, 102, 102, 0.5)",
  greySuperLight: "#F5F0EF",
  greyMegaLight: "#FBFBFB",
  orange: "#F6A800",
  orangeLight: "#FBDC98",
  orangeSlightlyTranslucent: "rgba(246, 168, 0, 0.8)",
  orangeTranslucent: "rgba(246, 168, 0, 0.5)",
  orangeVeryTranslucent: "rgba(246, 168, 0, 0.2)",
  purple: "#6F54BD",
  purpleLight: "#CF4DBE",
  red: "#E63D3D",
  redLight: "rgba(230, 61, 61, 0.1)",
  teal: "#21A4C5",
  tealLight: "#1DC0F1",
  turquiose: "#179CA4",
};

export const fontFamilies = {
  FredokaOne: "'Fredoka One', 'Nunito', 'sans-serif'",
};
