import React, { useEffect, useState } from "react";
import { Image as RNImage, StyleSheet, View } from "react-native";

interface Props {
  source: string;
}
const Image = ({ source }: Props) => {
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);

  useEffect(() => {
    RNImage.getSize(source, (w, h) => {
      setWidth(w);
      setHeight(h);
    });
  }, []);
  return (
    <View style={styles.container}>
      <RNImage
        source={source}
        style={[styles.messageImage, { aspectRatio: width/height }]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: "100%",
  },
  messageImage: {
    width: "100%",
    borderRadius: 5,
  },
});

export default Image;
