import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { fontFamilies } from "../shared/theme";

interface Props {
  url: string;
  text?: string;
  body?: string;
}
const Link = ({ url, text, body }: Props) => {
  const label = url.includes("pdf")
    ? "PDF"
    : url.includes("www.digibete.org")
    ? "Website"
    : null;
  return (
    <View>
      <Text
        style={styles.bubbleText}
        accessibilityRole="link"
        href={url}
        hrefAttrs={{
          target: "_blank",
        }}
        // onPress={() =>
        //   Linking.canOpenURL(url).then((supported) => {
        //     return Linking.openURL(url, '_blank');
        //   })
        // }
      >
        {label && (
          <>
            <Text style={styles.bubbleTextFiletype}>{label}</Text>
          </>
        )}
        <Text style={styles.link}>{text ?? url}</Text>
        {body && (
          <Text
            style={[
              styles.bubbleText,
              styles.bubbleTextDescription,
              styles.mt4,
            ]}
          >
            {body.length > 70 ? body.slice(0, 70) + "..." : body}
          </Text>
        )}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  bubbleText: {
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    marginBottom: 2,
    color: "inherit",
    breakWord: "break-all",
    wordBreak: "break-word",
    fontFamily: fontFamilies.FredokaOne,
  },
  bubbleTextTitle: {
    fontWeight: "bold",
    marginBottom: 6,
  },
  bubbleTextDescription: {
    fontSize: 13,
    marginBottom: 6,
  },
  bubbleTextFiletype: {
    color: "#666",
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: 4,
  },
  mt4: {
    marginTop: 4,
  },
  link: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    fontWeight: "bold",
  },
});

export default Link;
