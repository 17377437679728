import { StyleSheet, View } from "react-native";
import { useState } from "react";
import { useFonts } from "expo-font";
import { SafeAreaView } from "react-native-safe-area-context";

import Home from "./screens/Home";
import Chat from "./screens/Chat";

export default function App() {
  const [fontsLoaded] = useFonts({
    "Fredoka One": require("./assets/fonts/Fredoka One.ttf"),
  });
  const [screen, setScreen] = useState("Home");
  return (
    <SafeAreaView>
      <View style={styles.container}>
        <View style={styles.phone}>
          {screen === "Home" && <Home onLaunchChat={() => setScreen("Chat")} />}
          {screen === "Chat" && <Chat onBack={() => setScreen("Home")} />}
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#eee",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  phone: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "stretch",
    height: "100%",
    width: "100%",
    maxWidth: 406,
    maxHeight: 780,
  },
});
