// Watson Assistant API documentation:
// https://cloud.ibm.com/apidocs/assistant/assistant-v2

const { WATSON_API_KEY } = process.env;
const URL =
  "https://api.eu-gb.assistant.watson.cloud.ibm.com/instances/2d345a2c-446b-4ba2-846b-ef5e896a5fdd";
const ASSISTANT_ID = "44f7c249-2703-4241-b32a-da4fe5d82a01";

const basicAuth = Buffer.from("apikey:" + WATSON_API_KEY).toString("base64");

export const startSession = async () => {
  let body = {};

  return fetch(
    URL + "/v2/assistants/" + ASSISTANT_ID + "/sessions?version=2021-11-27",
    {
      method: "POST",
      headers: {
        Authorization: `Basic ${basicAuth}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
    });
};

export const endSession = async (sessionId: string) => {
  return fetch(
    URL +
      "/v2/assistants/" +
      ASSISTANT_ID +
      `/sessions/${sessionId}?version=2021-11-27`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${basicAuth}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
    });
};

export const messageRequest = async (
  sessionId: string,
  userId: string,
  input: string,
  context = {}
) => {
  if (!sessionId) {
    return Error("No session id provided");
  }

  let body = {
    alternate_intents: true,
    input: {
      text: input,
    },
    user_id: userId,
    context,
  };

  return fetch(
    URL +
      "/v2/assistants/" +
      ASSISTANT_ID +
      `/sessions/${sessionId}/message?version=2021-11-27`,
    {
      method: "POST",
      headers: {
        Authorization: `Basic ${basicAuth}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
    });
};
